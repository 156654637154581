import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
      message: "",
      previous_link: "",
      previous_page: "",
      search_value:"",
      LoginData: {

      }
  },
  mutations: {
    saveData (state, value){
        state.LoginData = value;
        //localStorage.setItem('data',JSON.stringify(value))
    },
    savePreviosLink (state, value){
      state.previous_link = value;
    },
    savePreviosPage (state, value){
      state.previous_page = value;
    },
    saveSearch (state, value){
      state.search_value = value;
    },
    dataRefresh(state){
      if (localStorage.getItem("data") !== null){
        state.LoginData = JSON.parse(localStorage.getItem("data"));
      }
    }
  },
  watch:{
   
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  
  strict: process.env.NODE_ENV !== 'production',
  
})

export default store